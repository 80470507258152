import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import Homepage from './components/Homepage.vue';
import GalleryPage from './components/GalleryPage.vue';
import GalleryImg from './components/GalleryImg.vue';
import CanvaPage from './components/CanvaPage.vue';
import SendingPage from './components/SendingPage.vue';
import Vuex from 'vuex';
import {InlineSvgPlugin} from 'vue-inline-svg';
import IdleVue from 'idle-vue';
import TimeoutService from '../src/services/timeout.service';

const timeouts = {};
let mainPageReturn = 45000;

TimeoutService.get().then((res) => {
  res.data.rows.forEach((val) => {
    mainPageReturn = val.mainPageReturn;
  });
});

const eventsHub = new Vue();

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(InlineSvgPlugin);

const routes = [
  {path: '', redirect: '/home'},
  {path: '/home', component: Homepage},
  {path: '/canva', name: 'canva', component: CanvaPage},
  {path: '/sending', name: 'sendingPage', component: SendingPage, props: true},
  {path: '/gallery', name: 'gallery', component: GalleryPage, props: true},
  {path: '/admin', name: 'admin', component: GalleryPage},
  {path: '/gallery/:id', name: 'singleImg', component: GalleryImg, props: true},
];

const router = new VueRouter({
  routes,
});

const store = new Vuex.Store({
  state: {
    adminLogged: false,
    language: 'NO',
    createdPostcard: '',
    latestPostcards: [
      '../../assets/test/1.png',
      '../../assets/test/1.png',
      '../../assets/test/1.png',
      '../../assets/test/1.png',
      '../../assets/test/1.png',
      '../../assets/test/1.png',
    ],
    transition: 'fade',
    postcards: [],
    previewImagesRendered: false,
    postcardsLoaded: false,
    strokeSizes: {},
    timeouts: timeouts,
    forcedRerender: false,
  },
  mutations: {
    changeLanguage(state, lang) {
      if (lang) {
        state.language = lang
      } else {
        state.language = state.language === 'NO' ? 'EN' : 'NO';
      }
    },
    adminLoggedIn(state) {
      state.adminLogged = true;
    },
    adminLoggedOut(state) {
      state.adminLogged = false;
    },
    changeCurrentPostcard(state, postcard = '') {
      state.createdPostcard = postcard;
    },
    changeTransition(state, newVal) {
      state.transition = newVal;
    },
    applyPostcards(state, postcards) {
      state.postcards = postcards;
    },
    addNewPostcard(state, postcard) {
      state.postcards.unshift(postcard);
    },
    addLatestPostcards(state, postcard) {
      if (state.latestPostcards.length > 6) state.latestPostcards.shift();
      state.latestPostcards.unshift(postcard);
    },
    updateLatestPostcards(state, postcards) {
      state.latestPostcards = postcards;
    },
    renderImages(state) {
      state.previewImagesRendered = !state.previewImagesRendered;
    },
    postcardsLoaded(state, val) {
      state.postcardsLoaded = val;
    },
    loadStrokeSizes(state, sizes) {
      state.strokeSizes = sizes;
    },
    loadTimeouts(state, timeouts) {
      state.timeouts = timeouts;
    },
    forceRerender(state,  val) {
      state.forcedRerender = val;
    }
  },
  getters: {
    postcardLoaded: (state) => state.postcardsLoaded,
    getForcedRerender: (state) => state.forcedRerender,
    getPostcards: (state) => state.postcards,
    getAdminMode: (state) => state.adminLogged
  },
});

setTimeout(() => {
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: mainPageReturn,
    startAtIdle: false
  });
  console.log(mainPageReturn)
}, 1000);

Vue.filter('uppercase', function(value) {
  return value.toUpperCase();
});

Vue.mixin({
  computed: {
    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },
    isEnglish() {
      return this.$store.state.language === 'EN';
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    }
  },
});

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
