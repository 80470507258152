<template>
    <div id="canvaPageWrapper">
        <header>
            <div class="headerButtonWrapper">
                <router-link to="/home">
                    <inline-svg :src="require('../assets/svgs/uni_gfx/BT_home.svg')" @click="resetCurrentPostcard"/>
                </router-link>
            </div>
        </header>
        <div class="mainWrapper">
            <canvas id="canva" width="1500" height="850" :style="{height: '844px', width: '1495px'}"></canvas>'
            <div class="rightSide">
                <div class="sideSettings">
                    <draw-options
                    @clearCanvaMsg="clearCanva"
                    @undoStrokeMsg="undo"
                    @pickColorMsg="pickColor"
                    @pickThicknessMsg="pickThickness"
                    />
                </div>
                    <inline-svg
                    :src="require('../assets/svgs/uni_gfx/BT_send.svg')"
                    @click="sendPostcard"
                    />
            </div>
        </div>
    </div>
</template>

<script>
import DrawOptions from './P2/DrawOptions.vue';
const Atrament = require('atrament');

export default {
  name: 'CanvaPage',
  data() {
    return {
      btnText: 'send',
      headerBtnText: 'home',
      canva: undefined,
      sketchpad: null,
      strokes: [],
      prevSketch: null,
    };
  },
  watch: {
    isIdle(newVal) {
        if (newVal == true) {
            this.$store.commit('changeLanguage', 'NO');
            this.$store.commit('changeCurrentPostcard');
            this.$router.push({path: '/home'});
        }
    },
  },
  computed: {
    previousImage() {
      return this.$store.state.createdPostcard;
    },
  },
  mounted() {
    this.canva = document.getElementById('canva');
    this.sketchpad = new Atrament(this.canva, {color: '#012343', weight: 10});
    this.sketchpad.adaptiveStroke = false;
    this.sketchpad.recordStrokes = true;
    const ctx = this.canva.getContext('2d');

    if (this.previousImage !== '') {
      const img = new Image();
      img.onload = function() {
        ctx.drawImage(img, 0, 0);
      };
      img.src = this.previousImage;
    }

    this.sketchpad.addEventListener('strokerecorded', ({stroke}) => {
      if (!this.sketchpad.recordPaused) this.strokes.push(stroke);
    });

    this.sketchpad.addEventListener('strokestart', () => {
      this.prevSketch = this.canva.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    });
  },
  components: {
    DrawOptions,
  },
  methods: {
    sendPostcard() {
      const postcard = this.canva.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      this.$store.commit('changeCurrentPostcard', postcard);
      this.$router.push({name: 'sendingPage', params: {img: postcard}}).catch((err) => {
        if (err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')) {
          console.error(err);
        }
      });
    },
    clearCanva() {
      this.strokes = [];
      this.sketchpad.clear();
    },
    pickThickness(weight) {
      this.sketchpad.weight = ++weight;
    },
    pickColor(colorRef) {
      this.sketchpad.color = colorRef;
    },
    undoStroke() {
      this.strokes.pop();
      this.sketchpad.clear();
      this.sketchpad.recordPaused = true;
      for (const stroke of this.strokes) {
        this.sketchpad.mode = stroke.mode;
        this.sketchpad.weight = stroke.weight;
        this.sketchpad.smoothing = stroke.smoothing;
        this.sketchpad.color = stroke.color;
        this.sketchpad.adaptiveStroke = stroke.adaptiveStroke;

        const points = stroke.points.slice();
        const firstPoint = points.shift();
        let previousPoint = firstPoint;

        this.sketchpad.beginStroke(firstPoint.x, firstPoint.y);
        while (points.length > 0) {
          const point = points.shift();
          const {x, y} = this.sketchpad.draw(point.x, point.y, previousPoint.x, previousPoint.y);
          previousPoint = {x, y};
        }
        this.sketchpad.endStroke(previousPoint.x, previousPoint.y);
      }
      this.sketchpad.recordPaused = false;
    },
    undo() {
      const ctx = this.canva.getContext('2d');
      this.sketchpad.clear();
      const img = new Image();
      img.onload = function() {
        ctx.drawImage(img, 0, 0);
      };
      img.src = this.prevSketch;
    },
    resetCurrentPostcard() {
      this.$store.commit('changeCurrentPostcard');
    },
  },

};
</script>
