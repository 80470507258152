var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"homePageWrapper"}},[_c('header',[_c('div',{staticClass:"headerButtonWrapper"},[_c('inline-svg',{attrs:{"src":_vm.isEnglish ?
                require('../assets/svgs/buttons_text/BT_language_UK.svg') :
                require('../assets/svgs/buttons_text/BT_language_NO.svg')},on:{"click":_vm.changeLanguage}})],1)]),_c('div',{staticClass:"mainWrapper"},[_c('div',{staticClass:"createCardWrapper"},[_c('p',{staticClass:"ourBoatText"},[_c('inline-svg',{attrs:{"src":_vm.isEnglish ?
                require('../assets/svgs/buttons_text/main_heading_UK.svg') :
                require('../assets/svgs/buttons_text/main_heading_NO.svg'),"height":"89"}})],1),_c('inline-svg',{staticClass:"createCardSvg",attrs:{"src":require('../assets/svgs/uni_gfx/send_letter.svg')}})],1),_c('div',{staticClass:"latestCardsWrapper"},[(_vm.isRendered)?_c('latest-cards'):_vm._e()],1)]),_c('div',{staticClass:"buttonsWrapper"},[_c('router-link',{staticClass:"canvaBtn",attrs:{"to":"/canva"}},[_c('inline-svg',{attrs:{"src":_vm.isEnglish ?
                require('../assets/svgs/buttons_text/BT_greeting_UK.svg') :
                require('../assets/svgs/buttons_text/BT_greeting_NO.svg')}})],1),_c('router-link',{staticClass:"galleryBtn",attrs:{"to":"/gallery"}},[_c('inline-svg',{attrs:{"src":_vm.isEnglish ?
            require('../assets/svgs/buttons_text/BT_gallery_UK.svg') :
            require('../assets/svgs/buttons_text/BT_gallery_NO.svg')}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }