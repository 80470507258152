<template>
    <div class="galleryPageWrapper">
        <div class="spinnerWrapper" v-if="!loaded">
            <self-building-square-spinner
                class="spinner"
                :animation-duration="6000"
                :size="400"
                color="#ffffff"
            />
        </div>
        <header>
                <div :style="ifVisible" class="headerButtonWrapper">
                    <router-link to="/home">
                        <inline-svg :src="require('../assets/svgs/uni_gfx/BT_home.svg')"/>
                    </router-link>
                </div>
                <button class="authButton" v-if="ifAdminMode && ifAdminLogged" @click="logout">Logout</button>
                <p class="adminText" v-if="ifAdminMode && ifAdminLogged">ADMINISTRATOR MODE</p>
                <div class="headerIconsWrapper" :style="{top: '30px', display: 'flex', left: '23px'}">
                    <inline-svg :src="ifPrevPage ?
                    require('../assets/svgs/uni_gfx/Arrow.svg') :
                    require('../assets/svgs/uni_gfx/Arrow_disabled.svg')"
                    :style="{transform: 'rotate(270deg)', padding: '30px 0'}"
                    @click="decreaseActiveIndex"
                    />
                    <div class="spacer" :style="{width: '70px'}"/>
                    <inline-svg :src="ifNextPage ?
                    require('../assets/svgs/uni_gfx/Arrow.svg') :
                    require('../assets/svgs/uni_gfx/Arrow_disabled.svg')"
                    :style="{transform: 'rotate(90deg)', padding: '30px 0'}"
                    @click="increaseActiveIndex"
                    />
                </div>
            </header>
            <div v-if="ifAdminMode && !forcedRerender" id="adminPanel">
                <div class="authPanel">
                    <div class="login">
                        <p>Username</p>
                        <input v-model="username"/>
                        <p>Password</p>
                        <input v-model="password" type="password"/>
                        <button class="authButton" @click="login(username, password)">Login</button>
                    </div>
                </div>
            </div>
            <div v-show="confirmationShowed" class="removeConfirmation">
              <div class="removalBubble">
                <p>Do you want to delete this picture?</p>
                <div class="buttonContainer">
                    <button class="yesBtn" @click="deletePostcard">Yes</button>
                    <button class="noBtn" @click="closeConfirmation()">No</button>
                </div>
              </div>                                
            </div>
            <div class="mainWrapper">
                <p :style="ifVisible" class="exploreText">
                    <inline-svg :src="isEnglish ?
                    require('../assets/svgs/buttons_text/click_explore_UK.svg') :
                    require('../assets/svgs/buttons_text/click_explore_NO.svg')"
                    />
                </p>
                <div class="gallery">
                  <div class="galleryEntry" v-for="(n, i) in 24" :key="n">
                    <img :src="activePostcardGroup[i] ?
                        activePostcardGroup[i].postcard :
                        require('../assets/pngs/whitebackground.png')"
                        @click="redirectToImg(i)"
                    />
                    <inline-svg v-if="ifAdminLogged && ifAdminMode && activePostcardGroup[i]"
                     class="removeBtn"
                     :src="require('../assets/svgs/uni_gfx/cross.svg')"
                     @click="showConfirmation(activePostcardGroup[i], i+activePageIndex*24, i)"
                     />
                  </div>
                </div>
            </div>
    </div>
</template>

<script>
import PostcardService from '../services/postcard.service';
import {SelfBuildingSquareSpinner} from 'epic-spinners';
import {mapGetters} from 'vuex';

export default {
  name: 'GalleryPage',
  components: {SelfBuildingSquareSpinner},
  props: ['pageIndex'],
  data() {
    return {
      activePageIndex: this.pageIndex || 0,
      groupedPostcards: [],
      activePostcardGroup: [],
      postcards: [],
      password: '',
      username: '',
      loaded: false,
      confirmationShowed: false,
      removedPostcard: null,
      removedPictureGlobalIndex: 0,
      removedPictureLocalIndex: 0,
      forcedRerender: false,
    };
  },
  watch: {
    activePageIndex(newVal) {
      this.activePostcardGroup = this.groupedPostcards[newVal];
    },
    postcardLoaded(newVal) {
      if (newVal) this.loadPictures();
    },
    isIdle(newVal) {
      if (newVal == true) {
          this.$store.commit('changeLanguage', 'NO');
          this.$store.commit('adminLoggedOut');
          this.$router.push({path: '/home'});
      }
    },
  },
  created() {
      if (window.localStorage.adminActive === 'active') {
        this.$store.commit('adminLoggedIn');      
        this.forcedRerender = true;
      } else {
        this.forcedRerender = false;
      }

      if (window.localStorage.lastVisitedPage !== "-1") {
        this.activePageIndex = Number(window.localStorage.getItem('lastVisitedPage'))
      }

      if (window.localStorage.getItem('preservePostcardsArray') === 'true') {
        this.loadPictures();
        this.loaded = true;
      } else {
        PostcardService.getAll().then((res) => {
            this.$store.commit('applyPostcards', res.data.rows);
            this.loadPictures();
            this.loaded = true;
          });
      }

      window.localStorage.setItem('preservePostcardsArray', 'false');
      window.localStorage.setItem('adminActive', 'non-active');
      window.localStorage.setItem('lastVisitedPage', -1);
  },
  computed: {
    ...mapGetters({
      postcardLoaded: 'postcardLoaded',
      posts: 'getPostcards',
      adminMode: 'getAdminMode'
    }),
    ifAdminLogged() {
      return this.$store.state.adminLogged;
    },
    ifAdminMode() {
      return this.$route.name === 'admin';
    },
    ifVisible() {
      return this.ifAdminLogged ?
      {visibility: 'hidden'} : {}
    },
    slidesNr() {
      return Math.ceil(this.postcards.length/24);
    },
    ifNextPage() {
      const newIndex = this.activePageIndex + 1;
      const nextIndex = newIndex < this.slidesNr ? newIndex : -1;
      return nextIndex !== -1 ? true : false;
    },
    ifPrevPage() {
      const newIndex = this.activePageIndex - 1;
      const prevIndex = newIndex >= 0 ? newIndex : -1;
      return prevIndex !== -1 ? true : false;
    },
  },
  methods: {
    loadPictures() {
      this.postcards = this.posts;
      const visibleImgs = 24;
      for (let i = 0; i < this.postcards.length; i += visibleImgs) {
        const temp = this.postcards.slice(i, i + visibleImgs);
        this.groupedPostcards.push(temp);
      }
      this.activePostcardGroup = this.groupedPostcards[this.activePageIndex];
      this.$store.commit('changeTransition', 'fade');
      this.loaded = true;
    },
    increaseActiveIndex() {
            this.ifNextPage ? this.activePageIndex += 1 : null;
    },
    decreaseActiveIndex() {
            this.ifPrevPage ? this.activePageIndex -= 1 : null;
    },
    login(username, password) {
      if (username === 'admin' && password === 'iL0v3_b0AtS') {
        document.getElementById('adminPanel').remove();
        this.$store.commit('adminLoggedIn');
      }
    },
    logout() {
      if (this.ifAdminLogged) {
        this.$store.commit('adminLoggedOut');
      }
    },
    redirectToImg(i) {
      window.localStorage.setItem('preservePostcardsArray', 'true');
      if (this.activePostcardGroup[i]) {
        this.$router.push({name: 'singleImg', params:
                    {
                      pageIndex: this.activePageIndex,
                      postcard: this.activePostcardGroup[i],
                      index: i+this.activePageIndex*24,
                      id: this.activePostcardGroup[i].id,
                    },
        }).catch((err) => {
          if (err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')) {
            console.error(err);
          }
        });
      }
    },
    showConfirmation(postcard, listIndex, index) {
      this.confirmationShowed = true;
      this.removedPostcard = postcard;
      this.removedPictureGlobalIndex = listIndex;
      this.removedPictureLocalIndex = index;
    },
    closeConfirmation() {
      this.confirmationShowed = false;
    },
    deletePostcard() {
      delete this.postcards[this.removedPictureGlobalIndex];
      delete this.activePostcardGroup[this.removedPictureLocalIndex];
      PostcardService.delete(this.removedPostcard.id);

      this.$store.commit('applyPostcards', this.postcards);      
      this.$store.commit('renderImages', false);

      PostcardService.getLatest().then((res) => {
        const cards = res.data.rows;
        cards.forEach((val, i) => cards[i] = val.postcard);
        this.$store.commit('updateLatestPostcards', cards);
        this.$store.commit('renderImages', true);
      });

      setTimeout(() => {
        window.localStorage.setItem('adminActive', 'active');
        window.localStorage.setItem('lastVisitedPage', Number(this.activePageIndex));
        this.loadPictures();
        this.closeConfirmation();
        this.$router.go();
      }, 1000);
    },
  },
};
</script>
