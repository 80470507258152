var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"galleryPageWrapper"},[(!_vm.loaded)?_c('div',{staticClass:"spinnerWrapper"},[_c('self-building-square-spinner',{staticClass:"spinner",attrs:{"animation-duration":6000,"size":400,"color":"#ffffff"}})],1):_vm._e(),_c('header',[_c('div',{staticClass:"headerButtonWrapper",style:(_vm.ifVisible)},[_c('router-link',{attrs:{"to":"/home"}},[_c('inline-svg',{attrs:{"src":require('../assets/svgs/uni_gfx/BT_home.svg')}})],1)],1),(_vm.ifAdminMode && _vm.ifAdminLogged)?_c('button',{staticClass:"authButton",on:{"click":_vm.logout}},[_vm._v("Logout")]):_vm._e(),(_vm.ifAdminMode && _vm.ifAdminLogged)?_c('p',{staticClass:"adminText"},[_vm._v("ADMINISTRATOR MODE")]):_vm._e(),_c('div',{staticClass:"headerIconsWrapper",style:({top: '30px', display: 'flex', left: '23px'})},[_c('inline-svg',{style:({transform: 'rotate(270deg)', padding: '30px 0'}),attrs:{"src":_vm.ifPrevPage ?
                require('../assets/svgs/uni_gfx/Arrow.svg') :
                require('../assets/svgs/uni_gfx/Arrow_disabled.svg')},on:{"click":_vm.decreaseActiveIndex}}),_c('div',{staticClass:"spacer",style:({width: '70px'})}),_c('inline-svg',{style:({transform: 'rotate(90deg)', padding: '30px 0'}),attrs:{"src":_vm.ifNextPage ?
                require('../assets/svgs/uni_gfx/Arrow.svg') :
                require('../assets/svgs/uni_gfx/Arrow_disabled.svg')},on:{"click":_vm.increaseActiveIndex}})],1)]),(_vm.ifAdminMode && !_vm.forcedRerender)?_c('div',{attrs:{"id":"adminPanel"}},[_c('div',{staticClass:"authPanel"},[_c('div',{staticClass:"login"},[_c('p',[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('p',[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('button',{staticClass:"authButton",on:{"click":function($event){return _vm.login(_vm.username, _vm.password)}}},[_vm._v("Login")])])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.confirmationShowed),expression:"confirmationShowed"}],staticClass:"removeConfirmation"},[_c('div',{staticClass:"removalBubble"},[_c('p',[_vm._v("Do you want to delete this picture?")]),_c('div',{staticClass:"buttonContainer"},[_c('button',{staticClass:"yesBtn",on:{"click":_vm.deletePostcard}},[_vm._v("Yes")]),_c('button',{staticClass:"noBtn",on:{"click":function($event){return _vm.closeConfirmation()}}},[_vm._v("No")])])])]),_c('div',{staticClass:"mainWrapper"},[_c('p',{staticClass:"exploreText",style:(_vm.ifVisible)},[_c('inline-svg',{attrs:{"src":_vm.isEnglish ?
                require('../assets/svgs/buttons_text/click_explore_UK.svg') :
                require('../assets/svgs/buttons_text/click_explore_NO.svg')}})],1),_c('div',{staticClass:"gallery"},_vm._l((24),function(n,i){return _c('div',{key:n,staticClass:"galleryEntry"},[_c('img',{attrs:{"src":_vm.activePostcardGroup[i] ?
                    _vm.activePostcardGroup[i].postcard :
                    require('../assets/pngs/whitebackground.png')},on:{"click":function($event){return _vm.redirectToImg(i)}}}),(_vm.ifAdminLogged && _vm.ifAdminMode && _vm.activePostcardGroup[i])?_c('inline-svg',{staticClass:"removeBtn",attrs:{"src":require('../assets/svgs/uni_gfx/cross.svg')},on:{"click":function($event){return _vm.showConfirmation(_vm.activePostcardGroup[i], i+_vm.activePageIndex*24, i)}}}):_vm._e()],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }