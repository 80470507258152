<template>
    <div class="sendingPageWrapper">
        <header>
            <div class="headerButtonWrapper">
                <router-link to="/home">
                    <inline-svg
                    :src="require('../assets/svgs/uni_gfx/BT_home.svg')"
                    @click="resetCurrentPostcard"
                    />
                </router-link>
            </div>
            <div class="headerTextWrapper">
                <inline-svg :src="isEnglish ?
                require('../assets/svgs/buttons_text/enter_name_UK.svg') :
                require('../assets/svgs/buttons_text/enter_name_NO.svg')"/>
            </div>
            <div class="headerIconsWrapper">
                <router-link to="/canva">
                    <inline-svg
                    :src="require('../assets/svgs/uni_gfx/BT_close.svg')"
                    />
                </router-link>
            </div>
        </header>
        <div class="mainWrapper">
            <div class="postcardSides">
                <div class="canvaBackground">
                    <p class="todaysDate" :class="{englishVersion: isEnglish}"> {{today}} </p>
                </div>
                <div class="canvaWrapper">
                    <p class="dedicationText">{{dedication}}</p>
                    <p class="fromText">{{from}}</p>
                    <inline-svg
                      class="stamp"
                      :src="require('../assets/svgs/uni_gfx/stamp.svg')"
                    />
                    <inline-svg
                      class="canva-border-top"
                  :src="require('../assets/svgs/uni_gfx/canvaBorderBottom.svg')"
                    />
                    <canvas id="canvaName" width="1000" height="650"></canvas>
                    <inline-svg
                    class="canva-border-btm"
                  :src="require('../assets/svgs/uni_gfx/canvaBorderBottom.svg')"
                    />
                    <inline-svg
                    class="canva-border-right"
                  :src="require('../assets/svgs/uni_gfx/canvaBorderRight.svg')"
                    />
                    <inline-svg
                    class="canva-border-left"
                  :src="require('../assets/svgs/uni_gfx/canvaBorderRight.svg')"
                  />
                </div>
                <img class="pulledPostcard" :src="img" @click="isImg"/>
            </div>
        </div>
        <div class="sendingButton">
            <router-link to="/home">
                <inline-svg
                :src="require('../assets/svgs/uni_gfx/BT_send_big.svg')"
                @click="sendPostcard"
                />
            </router-link>
        </div>
    </div>
</template>

<script>
const Atrament = require('atrament');
import PostcardService from '../services/postcard.service';

export default {
  name: 'SendingPage',
  props: ['img'],
  data() {
    return {
      canva: '',
      sketchpad: '',
      lang: this.$store.state.language,
      date: new Date(),
    };
  },
  computed: {
    isImg() {
      return true;
    },
    today() {
      let month;
      let formattedDate;
      const [day, year] = [this.date.getDate(), this.date.getFullYear()];
      if (this.lang == 'EN') {
        month = this.date.toLocaleString('en', {month: 'long'});
        const nth = function(day) {
          switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
          }
        };
        formattedDate = `${day}${nth(day)} ${month} ${year}`;
      } else if (this.lang == 'NO') {
        month = this.date.toLocaleString('no', {month: 'long'});
        formattedDate = `${day}. ${month} ${year}`;
      }
      return formattedDate;
    },
    dedication() {
      return this.lang === 'NO' ? 'Til Museum Nord' : 'To Museum Nord';
    },
    from() {
      return this.lang === 'NO' ? 'Fra' : 'From';
    },
  },
  watch: {
      isIdle(newVal) {
        if (newVal == true) {
            this.$store.commit('changeLanguage', 'NO');
            this.$store.commit('changeCurrentPostcard');
            this.$router.push({path: '/home'});
        }
    },
  },
  mounted() {
    this.canva = document.getElementById('canvaName');
    this.sketchpad = new Atrament(this.canva, {color: 'black', weight: 10});
    this.sketchpad.adaptiveStroke = false;
  },
  methods: {
    sendPostcard() {
      const postcard = this.$store.state.createdPostcard;
      const namePostcard = this.canva.toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
      const data = {
        'postcard': postcard,
        'namePost': namePostcard,
        'stamp': this.date,
      };
      PostcardService.create(data);
      this.$store.commit('addLatestPostcards', postcard);
      this.$store.commit('changeCurrentPostcard', '');
    },
    resetCurrentPostcard() {
      this.$store.commit('changeCurrentPostcard', '');
    },
  },
};
</script>
