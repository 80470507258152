<template>
    <div class="sendingPageWrapper">
        <header>
            <div class="headerButtonWrapper">
            <inline-svg
                :src="require('../assets/svgs/uni_gfx/BT_Back.svg')"
                @click="goHome"
            />
            </div>
            <div class="headerIconsWrapper">
                <div class="headerIconsWrapper" :style="{top: '10px', display: 'flex', paddingRight: '15px'}">
                    <inline-svg :src="ifPrevPage ?
                    require('../assets/svgs/uni_gfx/Arrow.svg') :
                    require('../assets/svgs/uni_gfx/Arrow_disabled.svg')"
                    :style="{transform: 'rotate(270deg)', padding: '30px 0'}"
                    @click="leftSlide()"
                    />
                    <div class="spacer" :style="{width: '68px'}"/>
                    <inline-svg :src="ifNextPage ?
                    require('../assets/svgs/uni_gfx/Arrow.svg') :
                    require('../assets/svgs/uni_gfx/Arrow_disabled.svg')"
                    :style="{transform: 'rotate(90deg)', padding: '30px 0'}"
                    @click="rightSlide()"
                    />
                </div>
            </div>
        </header>
        <div class="mainWrapper">
            <div class="postcardSides">
                <div class="canvaBackground">
                    <p class="todaysDate"> {{insertionDate}} </p>
                </div>
                <div class="canvaWrapper">
                    <p class="dedicationText">{{dedication}}</p>
                    <p class="fromText">{{from}}</p>
                    <inline-svg class="stamp" :src="require('../assets/svgs/uni_gfx/stamp.svg')"/>
                    <inline-svg class="canva-border-top" :src="require('../assets/svgs/uni_gfx/canvaBorderBottom.svg')"/>
                    <img id="canvaName" :src="currentPostcard.namePost"/>
                    <inline-svg class="canva-border-btm" :src="require('../assets/svgs/uni_gfx/canvaBorderBottom.svg')"/>
                    <inline-svg class="canva-border-right" :src="require('../assets/svgs/uni_gfx/canvaBorderRight.svg')"/>
                    <inline-svg class="canva-border-left" :src="require('../assets/svgs/uni_gfx/canvaBorderRight.svg')"/>
                </div>
                <img class="pulledPostcard" :src="currentPostcard.postcard"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SendingPage',
  props: ['postcard', 'index', 'pageIndex'],
  data() {
    return {
      lang: this.$store.state.language,
      currentPostcard: this.postcard,
      postcardNr: this.$store.state.postcards.length,
      currentIndex: this.index,
    };
  },
  watch: {
    currentIndex(newVal) {
      this.currentPostcard = this.$store.state.postcards[newVal];
    },
    isIdle(newVal) {
        if (newVal == true) {
            this.$store.commit('changeLanguage', 'NO');
            this.$store.commit('changeCurrentPostcard');
            this.$router.push({path: '/home'});
        }
    },
  },
  computed: {
    insertionDate() {
      let month;
      let formattedDate;
      const date = new Date(this.currentPostcard.stamp);
      const [day, year] = [date.getDate(), date.getFullYear()];
      if (this.lang == 'EN') {
        month = date.toLocaleString('en', {month: 'long'});
        const nth = function(day) {
          switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
          }
        };
        formattedDate = `${day}${nth(day)} ${month} ${year}`;
      } else if (this.lang == 'NO') {
        month = date.toLocaleString('no', {month: 'long'});
        formattedDate = `${day}. ${month} ${year}`;
      }
      return formattedDate;
    },
    ifNextPage() {
      const newIndex = this.currentIndex + 1;
      const nextIndex = newIndex < this.postcardNr ? newIndex : -1;
      return nextIndex !== -1 ? true : false;
    },
    ifPrevPage() {
      const newIndex = this.currentIndex - 1;
      const prevIndex = newIndex >= 0 ? newIndex : -1;
      return prevIndex !== -1 ? true : false;
    },
    dedication() {
      return this.lang === 'NO' ? 'Til Museum Nord' : 'To Museum Nord';
    },
    from() {
      return this.lang === 'NO' ? 'Fra' : 'From';
    },
  },
  methods: {
    goHome() {
      this.$store.commit('changeTransition', 'slide');
      this.$router.push({name: 'gallery', params: {pageIndex: this.pageIndex}}).catch((err) => {
        if (err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')) {
          console.error(err);
        }
      });
    },
    leftSlide() {
                this.ifPrevPage ? this.currentIndex -= 1 : null;
    },
    rightSlide() {
                this.ifNextPage ? this.currentIndex += 1 : null;
    },
  },
};
</script>
