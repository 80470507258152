<template>
  <div id="app">
    <div v-if="!loadingFinished && !adminMode && !ifAdminRoute" class="appLoader">
      <div class="animatedLoad"> {{loaderText}} </div>
    </div>
    <div class="appContent">
      <transition
        :name="$store.state.transition"
        mode="out-in"
        >
        <router-view>
        </router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import PostcardService from './services/postcard.service';
import StrokeSizesService from './services/strokeSizes.service';
import TimeoutService from './services/timeout.service';
import {mapGetters} from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      loaderText: 'Application is loading...',
      loadingFinished: false,
    }
  },
  computed: {
    ...mapGetters({
      adminMode: 'getAdminMode'
    }),
    ifAdminRoute() {
      return this.$route.name;
    },
  },
  mounted() {
    if (this.adminMode) this.loadingFinished = true;
    PostcardService.getLatest().then((res) => {
      const cards = res.data.rows;
      cards.forEach((val, i) => cards[i] = val.postcard);
      this.$store.commit('updateLatestPostcards', cards);
      this.$store.commit('renderImages', true);
    });

    StrokeSizesService.get().then((res) => {
      const strokeSizes = {};
      res.data.rows.forEach((val) => {
        strokeSizes[val.stroke] = val.SVGsize;
      });
      this.$store.commit('loadStrokeSizes', strokeSizes);
    });

    TimeoutService.get().then((res) => {
      const timeouts = {};
      res.data.rows.forEach((val) => {
        timeouts['mainPageReturn'] = val.mainPageReturn;
      });
      this.$store.commit('loadTimeouts', timeouts);
    });

    setTimeout(() => {
      this.loadingFinished = true;
    }, 10000);
  }
};
</script>

<style>
  @import './assets/style.css';

  .appLoader {
    background-color: white;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
  }

  .animatedLoad {
    font-size: 25px;
    line-height: 100vh;
    text-align: center;
    transition: all .5s;
    color: white;    
    background-color: black;
  }
</style>
