import http from './conn';

class PostcardService {
  getAll() {
    return http.get('/postcard');
  }

  create(data) {
    return http.post('/postcard', data);
  }

  delete(id) {
    return http.delete(`/postcard/${id}`);
  }

  getPaginated(offset) {
    return http.get(`/postcard/gallery/${offset}`);
  }

  getLatest() {
    return http.get(`/postcard/latest`);
  }
}

export default new PostcardService();
