<template>
  <div class="optionsWrapper">
    <p class="strokeText"> {{strokeText}} </p>
    <div class="strokeOptions">
        <div v-for="(SVGsize, strokeNumber) in strokeThickness"
            :key="strokeNumber" class="strokeOption"
            :class="[
              {picked: isThicknessSelected(strokeNumber)},
              'size-'+strokeNumber
              ]"
            @click="pickThickness(strokeNumber)">
            <div :style="{
                height: SVGsize.toString() + 'px',
                width: SVGsize.toString() + 'px',
                backgroundColor: colors['black'],
                marginTop: ((50-SVGsize)/2).toString() + 'px'
                }" class="strokeCircle"/>
        </div>
    </div>
    <p class="colorText"> {{colorText}} </p>
    <div class="colorOptions">
        <div v-for="(colorcode, colorname) in colors"
            :key="colorname" class="colorOption"
            :style="{ backgroundColor: colorcode}"
            :class="[
              {picked: isColorSelected(colorcode)},
              colorname
              ]"
            @click="pickColor(colorcode)">
        </div>
    </div>
    <inline-svg class="backBtn"
    :src="require('../../assets/svgs/buttons_text/Back_button.svg')"
    @click="undoStroke"/>
    <inline-svg class="trashBtn"
    :src="require('../../assets/svgs/buttons_text/Trash_Button.svg')"
    @click="clearCanva"/>
  </div>
</template>

<script>

export default {
  name: 'DrawOptions',
  props: {
    canva: {
      'type': Object,
      'default': () => {},
    },
  },
  data() {
    return {
      colors: {
        'white': '#FFFFFF',
        'black': '#000000',
        'grey': '#CDCECC',
        'yellow': '#FEB85F',
        'dark-green': '#1F372C',
        'khaki': '#607268',
        'dark-mint': '#95B7A0',
        'light-mint': '#BED1C3',
        'navy': '#012343',
        'dark-blue': '#2D6688',
        'light-blue': '#7798B0',
        'babyblue': '#CED9E2',
        'red': '#8F0312',
        'bright-red': '#FD3F2D',
        'pink': '#FE9E88',
        'light-pink': '#FECEC0',
      },
      strokeThickness: this.$store.state.strokeSizes,
      chosenColor: '#012343',
      chosenThickness: '10',
    };
  },
  computed: {
    strokeText() {
      return this.$store.state.lang === 'EN' ? 'Stroke' : 'Strektykkelse';
    },
    colorText() {
      return this.$store.state.lang === 'EN' ? 'Colours' : 'Farger';
    },
  },
  methods: {
    undoStroke() {
      this.$emit('undoStrokeMsg');
    },
    pickColor(colorRef) {
      this.chosenColor = colorRef;
      this.$emit('pickColorMsg', colorRef);
    },
    pickThickness(weight) {
      this.chosenThickness = weight;
      this.$emit('pickThicknessMsg', weight);
    },
    clearCanva() {
      this.$emit('clearCanvaMsg');
    },
    isColorSelected(colorcode) {
      return colorcode === this.chosenColor;
    },
    isThicknessSelected(weight) {
      return weight === this.chosenThickness;
    },
  },
};
</script>
