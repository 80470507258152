var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"optionsWrapper"},[_c('p',{staticClass:"strokeText"},[_vm._v(" "+_vm._s(_vm.strokeText)+" ")]),_c('div',{staticClass:"strokeOptions"},_vm._l((_vm.strokeThickness),function(SVGsize,strokeNumber){return _c('div',{key:strokeNumber,staticClass:"strokeOption",class:[
            {picked: _vm.isThicknessSelected(strokeNumber)},
            'size-'+strokeNumber
            ],on:{"click":function($event){return _vm.pickThickness(strokeNumber)}}},[_c('div',{staticClass:"strokeCircle",style:({
              height: SVGsize.toString() + 'px',
              width: SVGsize.toString() + 'px',
              backgroundColor: _vm.colors['black'],
              marginTop: ((50-SVGsize)/2).toString() + 'px'
              })})])}),0),_c('p',{staticClass:"colorText"},[_vm._v(" "+_vm._s(_vm.colorText)+" ")]),_c('div',{staticClass:"colorOptions"},_vm._l((_vm.colors),function(colorcode,colorname){return _c('div',{key:colorname,staticClass:"colorOption",class:[
            {picked: _vm.isColorSelected(colorcode)},
            colorname
            ],style:({ backgroundColor: colorcode}),on:{"click":function($event){return _vm.pickColor(colorcode)}}})}),0),_c('inline-svg',{staticClass:"backBtn",attrs:{"src":require('../../assets/svgs/buttons_text/Back_button.svg')},on:{"click":_vm.undoStroke}}),_c('inline-svg',{staticClass:"trashBtn",attrs:{"src":require('../../assets/svgs/buttons_text/Trash_Button.svg')},on:{"click":_vm.clearCanva}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }