<template>
  <div class="latestCards">
    <div class="cardsGrid">
      <div id="card1">
        <img :src="postcards[5]">
      </div>
      <div id="card2">
        <img :src="postcards[4]"/>
      </div>
      <div id="card3">
        <img :src="postcards[3]"/>
      </div>
      <div id="card4">
        <img :src="postcards[2]"/>
      </div>
      <div id="card5">
        <img :src="postcards[1]"/>
      </div>
      <div id="card6">
        <img :src="postcards[0]"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LatestCards',
  data() {
    return {
      postcards: this.$store.state.latestPostcards,
    };
  },
  watch: {
    postcards() {
      this.$forceUpdate();
    },
  },
};
</script>
