<template>
    <div id="homePageWrapper">
        <header>
            <div class="headerButtonWrapper">
                <inline-svg :src="isEnglish ?
                    require('../assets/svgs/buttons_text/BT_language_UK.svg') :
                    require('../assets/svgs/buttons_text/BT_language_NO.svg')
                    "
                    @click="changeLanguage"
                    />
            </div>
        </header>
        <div class="mainWrapper">
            <div class="createCardWrapper">
                <p class="ourBoatText">
                   <inline-svg :src="isEnglish ?
                    require('../assets/svgs/buttons_text/main_heading_UK.svg') :
                    require('../assets/svgs/buttons_text/main_heading_NO.svg')"
                    height="89"
                    />
                </p>
                <inline-svg
                :src="require('../assets/svgs/uni_gfx/send_letter.svg')"
                class="createCardSvg"
                />
            </div>

            <div class="latestCardsWrapper">
                <latest-cards v-if="isRendered"/>
            </div>
        </div>
        <div class="buttonsWrapper">
            <router-link to="/canva" class="canvaBtn">
                    <inline-svg :src="isEnglish ?
                    require('../assets/svgs/buttons_text/BT_greeting_UK.svg') :
                    require('../assets/svgs/buttons_text/BT_greeting_NO.svg')
                    "/>
            </router-link>
            <router-link to="/gallery" class="galleryBtn">
                <inline-svg :src="isEnglish ?
                require('../assets/svgs/buttons_text/BT_gallery_UK.svg') :
                require('../assets/svgs/buttons_text/BT_gallery_NO.svg')
                "
                />
            </router-link>
        </div>
    </div>
</template>

<script>
import LatestCards from './P1/LatestCards.vue';

export default {
  name: 'Homepage',
  components: {
    LatestCards,
  },
  data() {
    return {
      allPostcards: [],
    };
  },
  computed: {
    isRendered() {
      return this.$store.state.previewImagesRendered;
    },
  },
  mounted() {
    this.$store.commit('changeTransition', 'fade');
  },
  methods: {
    changeLanguage() {
      this.$store.commit('changeLanguage');
    },
  },
};
</script>
